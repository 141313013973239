import { Delete, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  Typography,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useState } from "react";
import {
  FE_Mitigation,
  FE_Scenario,
  OERatingResponse,
  OEReportPage,
} from "tracy-types";

import { theme } from "../../../theme";
import { MitigationActionsForm } from "../../scenarios/scenarios-steps/6-mitigation-actions";
import {
  ScenarioFlowStep,
  ScenariosFormMethods,
  ScenariosFormMountEventPayload,
} from "../../scenarios/types";

type PopupWithContentEvent =
  | "popup:open"
  | "popup:close"
  | "popup:save-and-close"
  | "popup:delete";

type MitigationDetailsPopupProps = {
  mitigation: FE_Mitigation | undefined;
  scenarios: FE_Scenario[] | undefined;
  open?: boolean;
  cardTitle: string;
  oeReportPage: OEReportPage;
  locked?: boolean;
};

export const MitigationDetailsPopup: PimoReactComponent<
  MitigationDetailsPopupProps,
  PopupWithContentEvent,
  FE_Mitigation
> = ({
  mitigation,
  open = false,
  locked = false,
  cardTitle,
  fireEvent,
  oeReportPage,
}) => {
  // this state is used to store the form methods of the mitigation action
  // currently being edited
  const [formMethods, setFormMethods] = useState<ScenariosFormMethods>();

  return (
    <Dialog
      onClose={() => fireEvent?.("popup:close")}
      open={open}
      maxWidth={"xl"}
      PaperProps={{ style: { borderRadius: 12 } }}
      slotProps={{
        backdrop: {
          sx: {
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography variant="h5" width={1000}>
          {cardTitle}
        </Typography>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <Box sx={{ p: 2 }}>
        <MitigationActionsForm
          mitigation={mitigation}
          hideUpdateButton
          disabled={!!locked}
          type="update"
          scenario={{} as FE_Scenario}
          oeRatings={{} as OERatingResponse}
          step={{} as ScenarioFlowStep}
          oeReportPage={oeReportPage}
          fireEvent={(event, payload) => {
            if (event !== "form:mount") {
              return;
            }

            setFormMethods(
              (payload as ScenariosFormMountEventPayload)?.formMethods
            );
          }}
        />
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <DialogActions sx={{ display: "flex", flexDirection: "row", p: 2 }}>
        <Button
          variant="outlined"
          color="error"
          sx={{ flex: "0 0 auto" }}
          startIcon={<Delete />}
          onClick={() =>
            fireEvent?.("popup:delete", { id: mitigation?.id } as FE_Mitigation)
          }
        >
          Delete
        </Button>
        <Box sx={{ flex: "1 1 100%" }}></Box>
        <Button
          onClick={() => fireEvent?.("popup:close")}
          variant="outlined"
          sx={{ flex: "0 0 auto" }}
        >
          Cancel
        </Button>
        <Button
          sx={{ flex: "0 0 auto" }}
          disabled={!!locked}
          onClick={() =>
            fireEvent?.(
              "popup:save-and-close",
              (
                formMethods?.getValues()?.mitigationActions as {
                  update: FE_Mitigation;
                }
              )?.update
            )
          }
          variant="contained"
          startIcon={<Save />}
        >
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import type {
  RiskType,
  ScenariosFormValues,
  ScenariosFormValuesRequest,
  ScenariosFormValuesResponse,
} from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchScenariosFormValues({
  id,
  year,
}: {
  id: number;
  year: number;
}): Promise<ScenariosFormValuesResponse | undefined> {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff/scenarios-form-values/${id}/${year}`,
      { credentials: "include" }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as ScenariosFormValuesResponse;
  } catch {
    return;
  }
}

export async function postScenariosFormValues({
  data,
  oeId,
  scenarioId,
  year,
  step,
  riskType,
  ignoreValidation,
}: {
  data: ScenariosFormValues;
  oeId: number;
  scenarioId: number;
  year: number;
  step?: ScenariosFormValuesRequest["step"];
  riskType?: RiskType;
  ignoreValidation: boolean;
}): Promise<ScenariosFormValuesResponse> {
  const response = await fetch(
    `${STRAPI_URL}/api/bff/scenarios-form-values/${oeId}/${scenarioId}/${year}`,
    {
      body: JSON.stringify({
        ...data,
        step,
        riskType,
        ignoreValidation,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );

  if (response.status === 400) {
    throw await response.json();
  }

  return response.json() as Promise<ScenariosFormValuesResponse>;
}

export async function resetScenarioParameters({
  oeId,
  scenarioId,
  year,
}: {
  oeId: number;
  scenarioId: number;
  year: number;
}): Promise<ScenariosFormValuesResponse> {
  const response = await fetch(
    `${STRAPI_URL}/api/bff/scenarios-form-values/${oeId}/${scenarioId}/${year}/reset-scenario-parameters`,
    {
      credentials: "include",
      method: "POST",
    }
  );

  if (response.status === 400) {
    throw await response.json();
  }

  return response.json() as Promise<ScenariosFormValuesResponse>;
}

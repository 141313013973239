import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  type CardProps,
  Chip,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Markdown, type PimoReactComponent } from "@pimo/pimo-app-builder";
import { Fragment, useState } from "react";
import type {
  FinancialRatingValue,
  ReputationalRatingValue,
} from "tracy-types";
import { getColorSchemeForRating } from "tracy-utils";

export type ProbabilityCardProps = {
  /** (optional) card props */
  cardProps?: CardProps;
  /** (optional) info icon modal headline and text */
  infoIcon?: { modalHeadline: string; modalText: string };
  /** probability rating */
  rating: {
    label: string;
    value?: FinancialRatingValue | ReputationalRatingValue;
  }[];
  /** card text */
  text: string;
  /** card title */
  title: string;
  /** (optional) comment */
  comment?: string;
};

export const ProbabilityCard: PimoReactComponent<ProbabilityCardProps> = ({
  cardProps,
  infoIcon,
  rating,
  text,
  title,
  comment,
}) => {
  const theme = useTheme();
  const [isInfoIconModalOpen, setIsInfoIconModalOpen] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        borderRadius: "12px",
        height: "100%",
        width: "100%",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          height: "100%",
          width: "100%",
          borderRadius: "12px",
          "@media print": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
          },
          ...cardProps?.sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            padding: 2,
            width: "100%",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "20px" }}>
            {title}
          </Typography>

          <Box sx={{ alignItems: "center", height: 30, display: "flex" }}>
            {infoIcon && (
              <>
                <IconButton onClick={() => setIsInfoIconModalOpen(true)}>
                  <InfoOutlined />
                </IconButton>
                <Dialog
                  maxWidth={"lg"}
                  open={isInfoIconModalOpen}
                  onClose={() => setIsInfoIconModalOpen(false)}
                  PaperProps={{ sx: { borderRadius: 5 } }}
                >
                  <Box
                    sx={{
                      background: theme.palette.background.paper,
                      p: 3,
                    }}
                  >
                    <Typography component="h2" variant="h5">
                      {infoIcon.modalHeadline}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Typography component="span">
                      <Markdown>{infoIcon.modalText}</Markdown>
                    </Typography>
                  </Box>
                  <DialogActions sx={{ p: 3 }}>
                    <Button
                      variant="contained"
                      onClick={() => setIsInfoIconModalOpen(false)}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Box>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
            width: "100%",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 2,
            width: "100%",
          }}
        >
          <Typography>{text}</Typography>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
            width: "100%",
          }}
        />
        {rating.map(({ label, value }) => {
          const { colorScheme } = getColorSchemeForRating(value);

          return (
            <Fragment key={label}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 2,
                  width: "100%",
                }}
              >
                <Typography>{label}</Typography>
                <Chip
                  label={value ?? "N/A"}
                  sx={{
                    background: colorScheme.chipColor,
                    color: colorScheme.textColor,
                    height: 28,
                    minWidth: 130,
                  }}
                />
              </Box>
              <Divider
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  height: "1px",
                  borderWidth: 0,
                  width: "100%",
                }}
              />
            </Fragment>
          );
        })}

        {comment && (
          <>
            <Divider
              sx={{
                bgcolor: theme.palette.secondary.main,
                height: "1px",
                borderWidth: 0,
                width: "100%",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 2,
                width: "100%",
              }}
            >
              <Typography>
                <i>Comment:</i> {comment}
              </Typography>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};

import { FE_Scenario } from "tracy-types";

export function filterScenariosWithMitigationBeforeAndAfterRemoval({
  id,
  scenarios,
}: {
  id: number;
  scenarios: FE_Scenario[];
}) {
  const scenariosWithMitigation = (scenarios ?? []).filter(({ mitigations }) =>
    mitigations?.some((mitigation) => id === mitigation.id)
  );
  const scenariosWithMitigationAfterRemoval = scenariosWithMitigation.filter(
    ({ mitigations }) =>
      mitigations.filter((mitigation) => id !== mitigation.id).length
  );

  return [scenariosWithMitigation, scenariosWithMitigationAfterRemoval];
}

export function getMitigationWarningTest(scenarios: FE_Scenario[]) {
  if (!scenarios.length) {
    return "";
  }

  if (scenarios.length === 1) {
    return `If you remove this mitigation, the scenario (${scenarios.map(({ name }) => name).join(", ")}) no longer has any mitigation. In this case, it is necessary to delete the entries for the residual risk (i.e. steps 7, 8 and 9) and perform the residual risk assessment again.`;
  }

  return `If you remove this mitigation, the scenarios (${scenarios.map(({ name }) => name).join(", ")}) no longer have any mitigation. In this case, it is necessary to delete the entries for the residual risk (i.e. steps 7, 8 and 9) and perform the residual risk assessment again.`;
}

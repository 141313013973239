import { Button, Card, Stack, Switch, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useState } from "react";

type GeneralSettingsEventNames = "group-dashboard-settings-changed";

type GeneralSettingsPayload = {
  lockGroupDashboardForOEs: boolean;
  lockGroupDashboardForOEsPreviousYears: boolean;
  year: number;
};

export type GeneralSettingsEventPayload = {
  lockGroupDashboardForOEs: boolean;
  lockGroupDashboardForOEsPreviousYears: boolean;
};

export const GeneralSettingsPage: PimoReactComponent<
  GeneralSettingsPayload,
  GeneralSettingsEventNames,
  GeneralSettingsEventPayload
> = ({
  lockGroupDashboardForOEs,
  lockGroupDashboardForOEsPreviousYears,
  year,
  fireEvent,
}) => {
  const isYearTheCurrentYear = year === new Date().getFullYear();

  const [formData, setFormData] = useState({
    lockGroupDashboardForOEs: lockGroupDashboardForOEs,
    lockGroupDashboardForOEsPreviousYears:
      lockGroupDashboardForOEsPreviousYears,
  });

  const [initialFormData, setInitialFormData] = useState({
    lockGroupDashboardForOEs: lockGroupDashboardForOEs,
    lockGroupDashboardForOEsPreviousYears:
      lockGroupDashboardForOEsPreviousYears,
  });

  useEffect(() => {
    setFormData({
      lockGroupDashboardForOEs: lockGroupDashboardForOEs,
      lockGroupDashboardForOEsPreviousYears:
        lockGroupDashboardForOEsPreviousYears,
    });
    setInitialFormData({
      lockGroupDashboardForOEs: lockGroupDashboardForOEs,
      lockGroupDashboardForOEsPreviousYears:
        lockGroupDashboardForOEsPreviousYears,
    });
  }, [lockGroupDashboardForOEs, lockGroupDashboardForOEsPreviousYears]);

  const hasChanges =
    formData.lockGroupDashboardForOEs !==
      initialFormData.lockGroupDashboardForOEs ||
    formData.lockGroupDashboardForOEsPreviousYears !==
      initialFormData.lockGroupDashboardForOEsPreviousYears;

  const handleSubmit = () => {
    fireEvent?.("group-dashboard-settings-changed", {
      lockGroupDashboardForOEs: formData.lockGroupDashboardForOEs,
      lockGroupDashboardForOEsPreviousYears:
        formData.lockGroupDashboardForOEsPreviousYears,
    });

    setInitialFormData({
      lockGroupDashboardForOEs: formData.lockGroupDashboardForOEs,
      lockGroupDashboardForOEsPreviousYears:
        formData.lockGroupDashboardForOEsPreviousYears,
    });
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">General Settings</Typography>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center" mb={6}>
        <Switch
          checked={
            isYearTheCurrentYear
              ? formData.lockGroupDashboardForOEs
              : formData.lockGroupDashboardForOEsPreviousYears
          }
          disabled={!isYearTheCurrentYear}
          onChange={(e) =>
            setFormData({
              ...formData,
              [isYearTheCurrentYear
                ? "lockGroupDashboardForOEs"
                : "lockGroupDashboardForOEsPreviousYears"]: e.target.checked,
            })
          }
        />
        <Typography sx={{ ml: 2 }}>
          {isYearTheCurrentYear
            ? "Disable Group Dashboard for all users for the current year"
            : "Disable Group Dashboard for all users for the previous years"}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ mt: "auto" }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ ml: 2 }}
          disabled={!hasChanges}
        >
          SAVE
        </Button>
      </Stack>
    </Card>
  );
};

import { NotificationsPlugin } from "@pimo/notifications-plugin";
import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  SideMenuEntryInterface,
  TwoColumnGridLayout,
  TwoColumnGridLayoutProps,
} from "@pimo/pimo-components";
import { FilterData } from "tracy-types";
import { isReadonly } from "tracy-utils";

import { TracyAppState } from "../../app";
import { GeneralSettingsPage } from "../../components/settings/general-settings";
import {
  ManageOEEventPayload,
  ManageOEsSettingsPage,
} from "../../components/settings/manage-oes-settings-page";
import { APP_ROUTES } from "../../constants";
import { fetchCopyDefaultScenariosIntoYearForAllOEs } from "../../helpers/fetch/fetch-copy-default-scenarios-into-year-for-all-oes";
import { fetchCopyDefaultScenariosIntoYearForOE } from "../../helpers/fetch/fetch-copy-default-scenarios-into-year-for-oe";
import {
  createOE,
  deleteOE,
  updateOE,
} from "../../helpers/fetch/fetch-manage-oe";
import { fetchOEs, fetchOEsForSettings } from "../../helpers/fetch/fetch-oes";
import {
  fetchGlobalSettings,
  updateGlobalSetting,
} from "../../helpers/fetch/global-setting";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface SettingsPluginState {}

interface OESettingsPayload {
  id?: number;
  name: string;
  cimid: string;
  hideFromGroupDashboard: boolean;
  hasShortcutAssessment: boolean;
}

export class SettingsPlugin
  implements Plugin<TracyAppState, SettingsPluginState, "viewname">
{
  route?: Route<"viewname", TracyAppState>;
  private _settingsView?: View<TracyAppState, TwoColumnGridLayoutProps>;
  private _app?: App<TracyAppState>;
  private _year?: number;

  onRegister(app: App<TracyAppState>): void {
    this._app = app;
    this._year = app.getAppState().year;

    app.on("state:changed", () => {
      if (this._year !== app.getAppState().year) {
        this._year = app.getAppState().year;
        void this._loadSettings();
      }
    });

    const entries: SideMenuEntryInterface[] = [
      {
        link: "/settings/manage-OEs",
        title: "Manage OEs",
        viewname: "manage-OEs",
      },
      {
        link: "/settings/notifications",
        title: "Notifications",
        viewname: "notifications",
      },
      {
        link: "/settings/general",
        title: "General Settings",
        viewname: "general",
      },
    ];

    this._settingsView = app.createView<TwoColumnGridLayoutProps>({
      name: "Settings",
      layout: new TwoColumnGridLayout({
        spacing: 1,
        enablePageBreak: false,
        workspaceRoute: APP_ROUTES.oeOverview,
        entries: entries,
        title: "Settings",
      }),
    });

    this.buildRoute();

    this._buildManageOEsView();
    this._buildNotificationsView();
    this._buildGeneralSettingsView();
  }

  private buildRoute() {
    if (!this._app || !this._settingsView) return;

    this.route = this._app.createRoute<"viewname">({
      path: "/settings/:viewname",
      view: this._settingsView,
    });

    this.route.on("load", () => {
      void this._loadSettings();
    });
  }

  private async _loadSettings() {
    if (!this._app) {
      return;
    }

    const { permissions, userProfile } = this._app.getAppState();

    if (
      !userProfile?.isAdmin ||
      !permissions?.length ||
      permissions.some(isReadonly)
    ) {
      this._app?.navigate(APP_ROUTES.oeOverview);
      return;
    }

    const globalSetting = await fetchGlobalSettings();
    const { oeClusters, oes: settingsOes } = await fetchOEsForSettings(
      this._app.getAppState().year
    );

    const oes = await fetchOEs();

    this._app?.patchAppState({
      filterState: {
        nameFilter: [],
        clusterFilter: [],
        searchQueryFilter: "",
      },
      oes,
      isLoading: false,
      settings: {
        lockGroupDashboardForOEs:
          globalSetting?.lockGroupDashboardForOEs ?? true,
        lockGroupDashboardForOEsPreviousYears:
          globalSetting?.lockGroupDashboardForOEsPreviousYears ?? true,
        oeClusters,
        oes: settingsOes,
      },
    });
  }

  private _buildManageOEsView() {
    const DEFAULT_FILTER_DATA = {
      nameFilter: [],
      clusterFilter: [],
      searchQueryFilter: "",
    } satisfies FilterData;

    const manageOEsView = this._settingsView?.addComponent({
      component: ManageOEsSettingsPage,
      layoutProps: { viewname: "manage-OEs", xs: 12 },
    });

    manageOEsView?.mapState(
      ({ settings: { oeClusters, oes }, year, filterState, ...props }) => {
        return {
          oeClusters: oeClusters ?? [],
          oes: oes ?? [],
          year: year,
          filterData: filterState ?? DEFAULT_FILTER_DATA,
          title: "Manage OEs",
          filterValues: {
            oeProjects: Array.isArray(props.oes)
              ? props.oes.map((oe) => oe.name)
              : [],
            clusters: oeClusters.map((cluster) => cluster.name),
          },
        };
      }
    );

    manageOEsView?.on("filter:apply", async ({ payload }) => {
      if (!this._app) {
        return;
      }

      const { oes } = await fetchOEsForSettings(
        this._app.getAppState().year,
        payload as FilterData
      );

      this._app.patchAppState({
        settings: {
          ...this._app.getAppState().settings,
          oes: oes ?? [],
        },
        filterState: payload as FilterData,
      });
    });

    manageOEsView?.on("filter:clear", async () => {
      if (!this._app) {
        return;
      }

      const { oeClusters, oes } = await fetchOEsForSettings(
        this._app.getAppState().year
      );

      this._app?.patchAppState({
        filterState: {
          nameFilter: [],
          clusterFilter: [],
          searchQueryFilter: "",
        },
        settings: {
          ...this._app.getAppState().settings,
          oeClusters,
          oes,
        },
      });
    });

    manageOEsView?.on("oe-created", async (event) => {
      const payload = event.payload as OESettingsPayload;

      if (!payload || !this._app) {
        return;
      }

      const { year } = this._app.getAppState();

      await createOE({ ...payload, year });
      void this._loadSettings();
    });

    manageOEsView?.on("oe-updated", async (event) => {
      const payload = event.payload as OESettingsPayload;

      if (!payload || !payload.id) {
        return;
      }

      await updateOE(payload.id, payload);
      void this._loadSettings();
    });

    manageOEsView?.on("oe-deleted", async (event) => {
      const payload = event.payload as OESettingsPayload;

      if (!payload || !payload.id) {
        return;
      }

      await deleteOE(payload.id);
      void this._loadSettings();
    });
    manageOEsView?.on(
      "create-default-scenarios-for-single-oe-clicked",
      async (event) => {
        this._app?.patchAppState({
          isLoading: true,
        });

        const payload = event.payload as ManageOEEventPayload;
        const oeId = payload?.id;
        const year = this._app?.getAppState().year;
        if (!oeId || !year) {
          return;
        }
        await fetchCopyDefaultScenariosIntoYearForOE(oeId, year);
        void this._loadSettings();

        this._app?.patchAppState({
          isLoading: false,
        });
      }
    );
    manageOEsView?.on(
      "create-default-scenarios-for-all-oes-clicked",
      async () => {
        this._app?.patchAppState({
          isLoading: true,
        });

        const year = this._app?.getAppState().year;

        if (!year) {
          return;
        }

        await fetchCopyDefaultScenariosIntoYearForAllOEs(year);
        void this._loadSettings();

        this._app?.patchAppState({
          isLoading: false,
        });
      }
    );
  }

  private _buildNotificationsView() {
    if (!this._settingsView || !this.route) {
      return;
    }

    const notificationsPlugin = this._app?.getPluginByName<
      NotificationsPlugin<TracyAppState>
    >("NotificationsPlugin");

    notificationsPlugin?.renderNotificationsManager({
      view: this._settingsView,
      route: this.route as Route,
      layoutProps: { viewname: "notifications", xs: 12 },
    });
  }

  private _buildGeneralSettingsView() {
    const generalSettingsView = this._settingsView?.addComponent({
      component: GeneralSettingsPage,
      layoutProps: { viewname: "general", xs: 12 },
    });

    generalSettingsView?.mapState((state) => {
      return {
        year: state.year,
        lockGroupDashboardForOEs: state.settings.lockGroupDashboardForOEs,
        lockGroupDashboardForOEsPreviousYears:
          state.settings.lockGroupDashboardForOEsPreviousYears,
      };
    });

    generalSettingsView?.on(
      "group-dashboard-settings-changed",
      async ({ payload }) => {
        if (!payload) {
          return;
        }

        await updateGlobalSetting(payload);
      }
    );
  }
}
